@font-face {
  font-family: 'DejaVu Sans';
  src: url('https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf')
    format('truetype');
}

@font-face {
  font-family: 'DejaVu Sans';
  font-weight: bold;
  src: url('https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Bold.ttf')
    format('truetype');
}

@font-face {
  font-family: 'DejaVu Sans';
  font-style: italic;
  src: url('https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf')
    format('truetype');
}

@font-face {
  font-family: 'DejaVu Sans';
  font-weight: bold;
  font-style: italic;
  src: url('https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf')
    format('truetype');
}

@font-face {
  font-family: 'DejaVu Sans Condensed';
  src: local('DejaVuSansCondensed'),
    url('/src/fonts/DejaVuSansCondensed/DejaVuSansCondensed-Bold.ttf')
      format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: 'DejaVu Sans Condensed';
  src: local('DejaVuSansCondensed'),
    url('/src/fonts/DejaVuSansCondensed/DejaVuSansCondensed.ttf')
      format('truetype');
}
