@font-face {
  font-family: 'Open Sans';
  src: local('OpenSans'),
    url('/src/fonts/OpenSans/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: 'Open Sans';
  src: local('OpenSans'),
    url('/src/fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
}
