.k-master-row td {
  padding-top: 0;
  padding-bottom: 0;
}
.k-appbar.k-appbar-top.k-appbar-static.k-appbar {
  border-radius: 4px;
}
body {
  background-color: #f5f5f9 !important;
}
.k-level-2 > .k-link > .k-panelbar-item-text {
  display: list-item;
  list-style-type: circle;
}
.k-level-1 > .k-link > .k-panelbar-item-text {
  display: list-item;
  list-style-type: square;
}
.k-panelbar-item > span {
  border-radius: 4px;
  margin-bottom: 2.5px;
  margin-top: 2.5px;
}
.k-panelbar .k-link.k-selected {
  background-color: rgba(105, 108, 255, 0.16) !important;
  color: #3f51b5 !important;
}
.k-panelbar-header.k-level-0 > span > span.k-panelbar-item-text {
  color: #3f51b5 !important;
}
li:has(+ span) {
  display: none;
}
.btn {
  @apply font-bold py-1 px-1 rounded;
}
.btn-blue {
  @apply text-white;
  background-color: #3f51b5;
}
.btn-blue:hover {
  @apply bg-blue-700;
}
.btn-red {
  @apply text-white;
  background-color: #d32f2f !important;
}
.btn-red:hover {
  opacity: 0.8;
}
.btn-secondary-blue {
  @apply border;
  border-color: #3f51b5;
  color: #3f51b5;
}
.btn-secondary-blue:hover {
  @apply text-white;
  background-color: #3f51b5;
}
.btn-secondary-red {
  @apply border;
  border-color: #d32f2f;
  color: #d32f2f;
}
.btn-secondary-red:hover {
  @apply text-white;
  background-color: #d32f2f;
}
.k-grid .k-grid-edit-row td {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.primary-color {
  color: #3f51b5;
}
.k-calendar-container {
  z-index: 10003 !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.issue-2-col tr {
  display: flex;
}
.issue-2-col tr td {
  flex: 11;
}
.issue-2-col tr td:first-child {
  flex: 1;
}
.k-tabstrip.w-full .k-animation-container.k-animation-container-relative {
  width: 100%;
}
.k-dialog-wrapper.delete-modal .k-window.k-dialog {
  border-top-color: #d32f2f;
  border-top-width: 7px;
  max-height: 90%;
}
.k-dialog-wrapper.edit-modal .k-window.k-dialog {
  border-top-color: #3f51b5;
  border-top-width: 7px;
  max-height: 90%;
}
.color-red {
  @apply text-white;
  background-color: #d32f2f !important;
}
.highlight {
  background-color: #a8edb3;
}

.cls-1 {
  font-size: 29.08px;
  font-family: Camber-Rg, Camber;
}
.cls-1,
.cls-4 {
  fill: #007cbb;
}
.cls-2 {
  letter-spacing: 0em;
}
.cls-3 {
  letter-spacing: 0em;
}
.cls-10 {
  fill: url(#Neues_Verlaufsfeld_1);
}
tr.k-table-row.k-master-row td.k-table-td.truncate {
  white-space: nowrap !important;
}
.k-grid-columnmenu-popup ul.k-reset.k-multicheck-wrap li input:focus {
  color: #3f51b5;
}
.k-checkbox.k-checkbox-md:checked:hover,
.k-checkbox.k-checkbox-md:checked:focus {
  border-color: #3f51b5;
  color: white;
  background-color: #3f51b5;
}
.k-grid-columnmenu-popup ul.k-reset.k-multicheck-wrap li input:checked:hover {
  color: #3f51b5;
  opacity: 0.8;
}
.k-dropdown-wrap > .k-select > .k-i-filter {
  color: 'black';
}
div.k-form-field {
  margin-top: 2px !important;
}
